import React, { useEffect, lazy, Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter, Route, Router } from 'react-router-dom'
import Header from './components/header/Header'
import BannerAd from './components/advertisements/BannerAd'
import Content from './components/Content'
import { geolocated } from "react-geolocated";
import AdminHeader from './components/admin_panel/AdminHeader';
import authservice from './components/Authentication/service/authservice';
// import DetectAdBlocker from './components/DetectAdBlocker';
import RightSideBar from './components/advertisements/RightSideBar';
import PageVisibility from 'react-page-visibility/dist/umd/PageVisibility';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import axios from 'axios';
import abecPages from './components/services/abecPages';
import Loader from 'react-loader-spinner';
import { useViewport } from './components/utility/useViewport';
import { useViewportBanner } from './components/utility/useViewport';
import { useViewportBillBoard } from './components/utility/useViewport';
import adService from './components/advertisements/service/adService';
import $ from 'jquery';
import PreventUserPopup from './components/PreventUserPopup';
//import { MAX_AD_ALLOWED } from './components/services/ABeCProperties';
import PreventMobilePopup from './components/PreventMobilePopup';
// import ShowPopup from './components/ShowPopup';

const Searchbar = lazy(() => import('./components/header/Searchbar'));
const LoginSearch = lazy(() => import('./components/header/LoginSearch'));
const Footer = lazy(() => import('./components/footer/Footer'));
const AdminPanel = lazy(() => import('./components/admin_panel/AdminPanel'));
const PremiumBanner = lazy(() => import("./components/listings/PremiumBanner"));
const SingleBanner = lazy(() => import("./components/listings/SingleBanner"));
const PremiumSlider = lazy(() => import("./components/listings/PremiumSlider"));

axios.interceptors.request.use(config => {
    if (config.url.includes('/admin') || config.url.includes('/super-admin') || config.url.includes('/login')) {
        config.withCredentials = true;
    }
    return config;
})

axios.interceptors.response.use(undefined, error => {
    if (error.response && error.response.status === 401) {
        // authservice.logOut();
        if (window.confirm('Looks like your session is expired. Login Again ?'))
            window.location = '/login/user'
        else
            window.location = '/'
    }
    return Promise.reject(error);
})

const App = (props) => {

    const isMobile = useViewport()
    const isTablet = useViewport()
    const isBanner = useViewportBanner()
    const isBillboard = useViewportBillBoard()
    const isAdmin = useSelector(state => state.isAdmin)
    const content_page = useSelector(state => state.content_page)
    const isLoggedIn = useSelector(state => state.is_login)
    const isLoading = useSelector(state => state.isLoading)
    const search_data = useSelector(state => state.search_data)
    const dispatch = useDispatch()
    const [zoomLevel, setZoomLevel] = useState(Math.round(window.devicePixelRatio * 100))
    const [locationUpdated, setLocationUpdated] = useState(true)
    const visibleAds = useSelector(state => state.visibleAds)
    const storedBillBoard = useSelector(state => state.storedBillBoard)
    const storedLR = useSelector(state => state.storedLR)

    const preventZoom = (event) => {
        if (event.ctrlKey == true && (event.which == '61' || event.which == '107' || event.which == '173' || event.which == '109' || event.which == '187' || event.which == '189')) {
            event.preventDefault();
        }
        // 107 Num Key  + | 109 Num Key - | 173 Min Key  hyphen/underscor | 61 Plus key  +/= key
    }

    useEffect(() => {
        const guestId = authservice.getCookie('hp.rocks.soon')
        const userId = authservice.getCurrentUserid()
        setLocationUpdated(true)
        // axios.get(process.env.REACT_APP_BACKEND_URL + `/abmgr/auth/location?guestId=${guestId}&userId=${userId}`).then(response => {
        //     setLocationUpdated(response.data.locationIsSaved)
        // }).catch(e => {
        //     console.log(e)
        // })
    }, [isLoggedIn])

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [storedBillBoard, storedLR])

    const handleBeforeUnload = (e) => {
        sessionStorage.setItem("billBoards", JSON.stringify(storedBillBoard))
        sessionStorage.setItem("largeRectangles", JSON.stringify(storedLR))
    }

    useEffect(() => {
        const billBoards = JSON.parse(sessionStorage.getItem('billBoards'));
        const largeRectangles = JSON.parse(sessionStorage.getItem('largeRectangles'));
        if (billBoards) {
            sessionStorage.removeItem('billBoards');
            dispatch({ type: 'STORED_BB_ADS', storedBillBoard: billBoards });
        }

        if (largeRectangles) {
            sessionStorage.removeItem('largeRectangles');
            dispatch({ type: 'STORED_LR_ADS', storedLR: largeRectangles });
        }
    }, [])

    useEffect(() => {
        dispatch({ type: 'BILLBOARD_USER', isBillboard: isBillboard })
    }, [isBillboard])

    useEffect(() => {
        dispatch({ type: 'MOBILE_USER', isMobile: isMobile })
    }, [isMobile])

    useEffect(() => {
        if (!visibleAds.includes('popup')) {
            // const unUsualBehaviour = visibleAds.length > MAX_AD_ALLOWED || zoomLevel < 100;
            const unUsualBehaviour = zoomLevel < 100;
            dispatch({ type: 'POPUP_OPEN_NOTIFICATION', isPopupOpen: unUsualBehaviour })
        }
    }, [visibleAds, zoomLevel])

    useEffect(() => {
        dispatch({ type: 'TABLET_USER', isTablet: isTablet })
    }, [isTablet])

    useEffect(() => {
        dispatch({ type: 'BANNER', isBanner: isBanner })
    }, [isBanner])

    useEffect(() => {
        document.addEventListener('keydown', preventZoom);

        $(window).bind('mousewheel DOMMouseScroll', event => {
            event.ctrlKey == true && event.preventDefault();
        });

        window.addEventListener("resize", (e) => {
            e.preventDefault()
            setZoomLevel(Math.round(window.devicePixelRatio * 100))
        });

        const user = authservice.getCurrentUser();
        if (user != null) {
            const isAdmin = user.accountType === 'ADMIN' || user.accountType === 'SUPER_ADMIN'
            if (!isAdmin) {
                adService.getViewedAds(user).then(data => {
                    dispatch({ type: 'ALL_VIEWED_ADS', viewedAds: [...data] })
                })
            }
            dispatch({ type: 'IS_LOGIN', is_login: true })
            dispatch({ type: 'ADMIN_LOGIN', isAdmin: isAdmin })
        } else {
            adService.getGuestViewedAds(authservice.getCookie('hp.rocks.soon')).then(data => {
                dispatch({ type: 'ALL_VIEWED_ADS', viewedAds: [...data] })
            })
        }

        const userLocation = JSON.parse(localStorage.getItem('users_location'))
        if (userLocation !== null) {
            dispatch({
                type: 'CURRENT_LOCATION', current_coords: {
                    ...userLocation
                }
            })
        }
        if (user == null) {
            if (navigator.geolocation) { // Check for geolocation support
                //navigator.geolocation.getCurrentPosition((position) => {

                const latitude = 17.4382401// position.coords.latitude;
                const longitude = 78.4558889 // position.coords.longitude;

                if (userLocation === null || latitude !== userLocation.latitude || longitude !== userLocation.longitude || !userLocation.visitorCountry) {
                    Promise.all([axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_GEO_API_KEY}`)])
                        .then(([response]) => {
                            let location = {
                                latitude: latitude,
                                longitude: longitude,
                            }
                            response.data.results[0].address_components.forEach(element => {
                                if (element.types.includes("administrative_area_level_1"))
                                    location.visitorState = element.long_name
                                if (element.types.includes("country"))
                                    location.visitorCountry = element.long_name
                            });
                            localStorage.setItem('users_location', JSON.stringify({ ...location }))
                            dispatch({
                                type: 'CURRENT_LOCATION', current_coords: {
                                    ...location
                                }
                            })
                        }).catch(() => {
                            alert('We are unable to determine you location, Please allow location access')
                        })
                }
                // }, error => {
                //     console.log(error)
                //     if (userLocation === null)
                //         alert('We are unable to determine you location, Please allow location access')
                // });
            }
        }

    }, [navigator.geolocation])

    return (
        <>
            {/* <MetaDecor></MetaDecor> */}
            <PageVisibility
                onChange={(isVisible) => {
                    dispatch({ type: 'PAGE_VISIBILITY', isPageVisible: isVisible })
                }}>
                <BrowserRouter>
                    <ReactNotification />
                    {/* <ShowPopup /> */}
                    {isLoading ? <div className="spinner">
                        <Loader
                            type="ThreeDots"
                            color="#FF9933"
                            height="90"
                            width="90"
                        />
                    </div> : null}
                    {isBillboard && window.innerWidth > 900 == true ? <PreventMobilePopup open={true} /> : null}
                    {/* <PreventUserPopup open={zoomLevel < 100 || visibleAds.length > MAX_AD_ALLOWED} userLoggedIn={isLoggedIn} /> */}
                    <PreventUserPopup open={zoomLevel < 100} userLoggedIn={isLoggedIn} />
                    {(isAdmin) ? <AdminHeader /> : <Header isTablet={isTablet} />}
                    <div className="App">

                        {!isAdmin && !isMobile && <Route path="/clips/:b/:hpId" exact component={Content} />}
                        {!isAdmin && !isMobile && <Route path="/shareclips/:b/:hpId" exact component={Content} />}
                        {!isAdmin &&
                            <Suspense fallback={<div>Loading Admin Panel...</div>}>
                                {content_page === 'ClipsPage' || content_page === 'LoginPage' || content_page === 'RegistrationPage' ? null : <Route path="/" render={(props) => isLoggedIn ? <LoginSearch {...props} isMobile={isMobile} /> : <Searchbar {...props} isMobile={isMobile} />} />}
                            </Suspense>}

                        <Suspense fallback={<div>Loading Admin Panel...</div>}>
                            <Route path="/admin-panel" component={AdminPanel} />
                        </Suspense>

                        {isAdmin && <Route path="/manage-profile" component={Content} />}

                        {/* <DetectAdBlocker pathname={window.location.pathname} /> */}
                        {isBillboard ? null :
                            <>
                                <Route exact path={["/", "/login/:required", "/search", "/processing/:id", "/contact", "/MyPages/:subcategory", "/MyReviews/:subcategory", "/MyVideos/:subcategory", "/MyClips/:subcategory", "/MyPhotos/:subcategory", "/MyAlbum/:subcategory", "/qualification", "/", "/addpost", "/advertise", "/listing-basic", "/terms-of-service",
                                    "/privacy-policy", "/cookies-policy", "/advertisement-policy", "/content-policy", "/refund-policy", "/success-story", "/success-story/archieve", "announcements", "/about-us", "/knowledge-base", "/we-are-at", "/advertisements", "/posts", "/referring", "/how-to-earn", "/promote", "/manageprofile", "/manageposts", "/manageadvertisement", "/viewreport",
                                    "/view-downline", "/listingperformance", "/referralactivity", "/withdrawal-history", "/earning-history", "/advtperformance", "/help", "/help/*", "/helpPage", "/updateadvertisement/:id",
                                    "/update-post/:id", "/order-history/:id", "/ad-order-history/:id", "/post-order-history/:id", "/subscription-orders/:id"]}
                                    component={BannerAd} />
                            </>}

                        {/* <Suspense fallback={<div>Loading Banner...</div>}>
                            <Route path="/l/:b/:hpId" exact component={
                                (search_data && !search_data.showBanner) ? SingleBanner : BannerAd
                            } />
                        </Suspense> */}

                        <Suspense fallback={<div>Loading Banner...</div>}>
                            <Route exact path="/update-post/content/premium/:id" component={PremiumSlider} />
                            <Route path={"/listing-premium"} component={PremiumSlider} />
                            <Route path="/listing-advanced" component={PremiumBanner} />
                            <Route exact path="/update-post/content/advanced/:id" component={PremiumBanner} />
                        </Suspense>

                        {/* <Route exact path="/contents/:username/:tab?" component={Content} /> */}
                        <Route exact path="/categories/:username" component={Content} />
                        <Route exact path="/content/:type/:subcategory/:tab" component={Content} />
                        <Route path="/registration" component={Content} />
                        <Route path="/activation-success/:username" component={Content} />
                        <Route path="/login/:required" component={Content} />
                        <Route path="/forgotpassword" component={Content} />
                        <Route path="/resendemail/:id" component={Content} />
                        <Route path="/otp/:verify" component={Content} />
                        {isMobile ?
                            <>
                                <Suspense fallback={<div>Loading Banner...</div>}>
                                    <Route path="/l/:b/:hpId" exact component={
                                        (search_data && !search_data.showBanner) ? SingleBanner : isBillboard ? null : BannerAd
                                    } />
                                </Suspense>
                                <Route path="/clips/:b/:hpId" exact component={Content} />
                                <Route path="/shareclips/:b/:hpId" exact component={Content} />
                                <div className="listings row">
                                    <Route exact path="/" component={Content} />
                                    <Route exact path="/processing/:id" component={Content} />
                                    <Route path="/search" component={Content} />
                                </div>
                                <Route path="/listing-basic" component={Content} />
                                <Route path="/listing-advanced" component={Content} />
                                <Route path="/listing-premium" component={Content} />

                                <Route path="/l/:b/:hpId" exact component={Content} />

                                <Route path="/single-listing/:hpId/:addressId" component={Content} />
                                <Route path="/MyPages/:subcategory" component={Content} />
                                <Route path="/MyReviews/:subcategory" component={Content} />
                                <Route path="/MyVideos/:subcategory" component={Content} />
                                <Route path="/MyClips/:subcategory" component={Content} />
                                <Route path="/MyPhotos/:subcategory" component={Content} />
                                <Route path="/MyAlbum/:subcategory" component={Content} />
                                <Route path="/contact" component={Content} />
                                <Route path="/advanced-search" component={Content} />
                                <Route path="/addpost" component={Content} />
                                <Route path="/advertise" component={Content} />
                                <Route path="/payment" component={Content} />
                                <Suspense fallback={<div>Loading Footer...</div>}>
                                    <Route path="/terms-of-service" component={Content} />
                                    <Route path="/privacy-policy" component={Content} />
                                    <Route path="/cookies-policy" component={Content} />
                                    <Route path="/advertisement-policy" component={Content} />
                                    <Route path="/content-policy" component={Content} />
                                    <Route path="/refund-policy" component={Content} />
                                    <Route path="/success-story" component={Content} />
                                    <Route path="/announcements" component={Content} />
                                    <Route path="/about-us" component={Content} />
                                    <Route path="/knowledge-base" component={Content} />
                                    <Route path="/we-are-at" component={Content} />
                                    <Route path="/advertisements" component={Content} />
                                    <Route path="/posts" component={Content} />
                                    <Route path="/referring" component={Content} />
                                    <Route path="/how-to-earn" component={Content} />
                                    <Route path="/promote" component={Content} />
                                </Suspense>
                                <Suspense fallback={<div>Loading Help Docs...</div>}>
                                    <Route path="/help" component={Content} />
                                    {/* <Route path="/help" component={Content} /> */}
                                </Suspense>

                                <Route path="/manageprofile" component={Content} />
                                <Route path="/managestaff" component={Content} />
                                <Route path="/manageposts" component={Content} />
                                <Route path="/manageadvertisement" component={Content} />
                                <Route path="/viewreport" component={Content} />
                                <Route path="/view-downline" component={Content} />
                                <Route path="/withdrawal-history" component={Content} />
                                <Route path="/earning-history" component={Content} />
                                <Route path="/qualification" component={Content} />
                                <Route path="/listingperformance" component={Content} />
                                <Route path="/advtperformance" component={Content} />
                                <Route path="/referralactivity" component={Content} />

                                <Route path="/updateadvertisement/:id" component={Content} />
                                <Route exact path="/update-post/:id" component={Content} />
                                <Route exact path="/update-post/content/premium/:id" component={Content} />
                                <Route exact path="/update-post/content/advanced/:id" component={Content} />
                                <Route path="/order/:id" component={Content} />
                                <Route path={["/ad-order-history/:id", "/post-order-history/:id", "/subscription-orders/:id"]} component={Content} />

                                {/**Show above components on the left side of LR ad */}

                                {content_page !== 'ListingPage' ? null : (isAdmin || isMobile) ? null : <Route path="/" component={RightSideBar} />}

                                {/**Show below components on the right side of LR ad */}
                                {/* <Route path="/registration" component={Content} /> */}
                                {/* <Route path="/otp/:verify" component={Content} /> */}
                                {/* <Route path="/resendemail/:id" component={Content} /> */}
                                {/* <Route path="/activation-success/:username" component={Content} /> */}
                                {/* <Route path="/login/:required" component={Content} /> */}
                                <Route path="/login-otp/:verify" component={Content} />
                                {/* <Route path="/forgotpassword" component={Content} /> */}
                            </> :
                            <>
                                <Suspense fallback={<div>Loading Banner...</div>}>
                                    <Route path="/l/:b/:hpId" exact component={
                                        (search_data && !search_data.showBanner) ? SingleBanner : BannerAd
                                    } />
                                </Suspense>
                                <div className="listings row">
                                    <Route exact path="/" component={Content} />
                                    <Route path="/processing/:id" component={Content} />
                                    <Route path="/search" component={Content} />
                                    <Route path="/MyPages/:subcategory" component={Content} />
                                    <Route path="/MyReviews/:subcategory" component={Content} />
                                    <Route path="/MyVideos/:subcategory" component={Content} />
                                    <Route path="/MyClips/:subcategory" component={Content} />
                                    <Route path="/MyPhotos/:subcategory" component={Content} />
                                    <Route path="/MyAlbum/:subcategory" component={Content} />
                                    <Route path="/contact" component={Content} />
                                    <Route path="/advanced-search" component={Content} />
                                    <Route path="/addpost" component={Content} />
                                    <Route path="/advertise" component={Content} />
                                    <Route path="/listing-basic" component={Content} />
                                    <Route path="/listing-advanced" component={Content} />
                                    <Route path="/listing-premium" component={Content} />

                                    <Route path="/l/:b/:hpId" exact component={Content} />

                                    <Route path="/single-listing/:hpId/:addressId" component={Content} />
                                    <Route path="/payment" component={Content} />

                                    <Suspense fallback={<div>Loading Help Docs...</div>}>
                                        <Route path="/help" component={Content} />
                                    </Suspense>

                                    <Suspense fallback={<div>Loading Footer...</div>}>
                                        <Route path="/terms-of-service" component={Content} />
                                        <Route path="/privacy-policy" component={Content} />
                                        <Route path="/cookies-policy" component={Content} />
                                        <Route path="/advertisement-policy" component={Content} />
                                        <Route path="/content-policy" component={Content} />
                                        <Route path="/refund-policy" component={Content} />
                                        <Route path="/success-story" component={Content} />
                                        <Route path="/announcements" component={Content} />
                                        <Route path="/about-us" component={Content} />
                                        <Route path="/knowledge-base" component={Content} />
                                        <Route path="/we-are-at" component={Content} />
                                        <Route path="/advertisements" component={Content} />
                                        <Route path="/posts" component={Content} />
                                        <Route path="/referring" component={Content} />
                                        <Route path="/how-to-earn" component={Content} />
                                        <Route path="/promote" component={Content} />
                                    </Suspense>

                                    <Route path="/manageprofile" component={Content} />
                                    <Route path="/managestaff" component={Content} />
                                    <Route path="/manageposts" component={Content} />
                                    <Route path="/manageadvertisement" component={Content} />
                                    <Route path="/viewreport" component={Content} />
                                    <Route path="/view-downline" component={Content} />
                                    <Route path="/withdrawal-history" component={Content} />
                                    <Route path="/earning-history" component={Content} />
                                    <Route path="/qualification" component={Content} />
                                    <Route path="/listingperformance" component={Content} />
                                    <Route path="/advtperformance" component={Content} />
                                    <Route path="/referralactivity" component={Content} />

                                    <Route path="/updateadvertisement/:id" component={Content} />
                                    <Route exact path="/update-post/:id" component={Content} />
                                    <Route exact path="/update-post/content/premium/:id" component={Content} />
                                    <Route exact path="/update-post/content/advanced/:id" component={Content} />
                                    <Route path="/order/:id" component={Content} />
                                    <Route path={["/ad-order-history/:id", "/post-order-history/:id", "/subscription-orders/:id"]} component={Content} />

                                    {/**Show above components on the left side of LR ad */}
                                    {content_page !== 'ListingPage' ? null : (isAdmin || isMobile) ? null : <Route path="/" component={RightSideBar} />}

                                    {/**Show below components on the right side of LR ad */}
                                    {/* <Route path="/registration" component={Content} /> */}
                                    {/* <Route path="/otp/:verify" component={Content} /> */}
                                    {/* <Route path="/resendemail/:id" component={Content} /> */}
                                    {/* <Route path="/activation-success/:username" component={Content} /> */}
                                    {/* <Route path="/login/:required" component={Content} /> */}
                                    <Route path="/login-otp/:verify" component={Content} />
                                    {/* <Route path="/forgotpassword" component={Content} /> */}
                                </div>
                            </>}
                    </div>
                    <Suspense fallback={<div>Loading Footer...</div>}>
                        {!isAdmin && content_page !== 'ClipsPage' && content_page !== 'LoginPage' && content_page !== 'RegistrationPage' && <Footer />}
                    </Suspense>
                </BrowserRouter>
            </PageVisibility>
        </>
    );
}

export default geolocated({
    positionOptions: {
        enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
})(App);
